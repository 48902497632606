.signature-modal-draw__line{
    position: relative;
    z-index:0;
    border-bottom:2px solid #959ca4;
    left:30px;
    right:30px;
    bottom:36px;
    border-radius:2px;
    min-width:200px;
}
@media only screen and (max-width: 900px) and (min-aspect-ratio: 3 / 2){
    .signature-modal-draw__line{
        bottom:18px;
    }
}
.signature-modal-draw__line:after,.signature-modal-draw__line:before{
    content:"";
    position:absolute;
    left:6px;
    bottom:6px;
    width:2px;
    height:16px;
    background-color:#959ca4
}
.signature-modal-draw__line:before{
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.signature-modal-draw__line:after{
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg)
}